import Box from '../ui/Box';
import VideoAsset, { VideoProps } from '../VideoAsset';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardVideoProps = VideoProps & { hover?: boolean };

export const CardVideo = ({ hover = true, ...props }: CardVideoProps) => {
  const { title, videoUrl } = useCard();

  return (
    videoUrl && (
      <Box className={style.videoWrap}>
        <Box className={style.videoInner({ hover })}>
          <VideoAsset
            videoUrl={videoUrl}
            title={title ?? ''}
            aria-label={title ?? ''}
            playing
            muted
            controls={false}
            loop
            {...props}
          />
        </Box>
      </Box>
    )
  );
};
